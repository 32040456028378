import { IEventTrackingBody } from "../../services/track-event/track-event-service";

export enum ClientTrackingEvent {
  "HAMBURGER_MENU_OPENED" = "HAMBURGER_MENU_OPENED",
  "NEWS_SEARCH_CLEARED" = "NEWS_SEARCH_CLEARED",
  "PAGE_VIEWED" = "PAGE_VIEWED",
  "SIGN_IN_CLICKED" = "SIGN_IN_CLICKED",
  "SEARCH_PERFORMED" = "SEARCH_PERFORMED",
  "LINK_CLICK" = "LINK_CLICK",
  "NAVIGATION_CLICK" = "NAVIGATION_CLICK",
  "PERFORMANCE" = "PERFORMANCE",
  "NEWS_READ" = "NEWS_READ",
}

export enum PageType {
  "SEARCH" = "SEARCH",
  "ARTICLE" = "ARTICLE",
  "AUTHOR" = "AUTHOR",
  "HOME" = "HOME",
  "CATEGORY" = "CATEGORY",
  "LIST" = "LIST",
  "CONTACT" = "CONTACT",
  "COMPANY" = "COMPANY",
}

export enum ClientTrackingArea {
  "HEADER" = "HEADER",
  "HAMBURGER_MENU" = "HAMBURGER_MENU",
  "FOOTER" = "FOOTER",
  "UPSELL" = "UPSELL",
  "MENTIONED_IN_THIS_ARTICLE" = "MENTIONED_IN_THIS_ARTICLE",
  "RELATED_ARTICLE" = "RELATED_ARTICLE",
  "ARTICLE_DEEP_LINK" = "ARTICLE_DEEP_LINK",
}

export enum SearchType {
  "ARTICLE" = "ARTICLE",
  "CONTACT" = "CONTACT",
  "COMPANY" = "COMPANY",
  "VIDEO" = "VIDEO",
}

export interface ITrackingDetails {
  event: ClientTrackingEvent;
  area?: ClientTrackingArea;
}

export const trackingDetails = (detailsObject: IEventTrackingBody) => {
  return JSON.stringify(detailsObject);
};
