import { useEffect } from "react";
/* 
node: the ref to your component. call useRef within your component and pass the node
open: the state of your popup, modal, overlay, etc if it's visible
outsideClickHandler: pass a function to run when clicking outside your component
*/
export const useClickOutside = (
  node: React.RefObject<HTMLElement>,
  open: boolean,
  outsideClickHandler: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (node.current && node.current.contains(e?.target as Node)) {
        // inside click
        return;
      }
      // outside click
      outsideClickHandler();
    };
    if (open) {
      document.addEventListener("mouseup", handleClickOutside);
    } else {
      document.removeEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [node, open, outsideClickHandler]);
};

export default useClickOutside;
