interface ISearchURLParams {
  baseUrl: string;
  redirectUrl: string;
  keyword?: string;
  to?: number;
  from?: number;
  thematiques?: string[];
  categories?: string[];
}

export const buildSearchURL = (searchUrlParams: ISearchURLParams) => {
  const { baseUrl, redirectUrl, keyword, from, to, thematiques, categories } =
    searchUrlParams;
  const url = new URL(redirectUrl, baseUrl);

  if (keyword) url.searchParams.set("keyword", keyword);
  if (from) url.searchParams.set("from", `${from}`);
  if (to) url.searchParams.set("to", `${to}`);
  if (thematiques)
    for (const item of thematiques) {
      url.searchParams.append("thematique", item);
    }
  if (categories)
    for (const item of categories) {
      url.searchParams.append("categorie", item);
    }

  return url.href;
};
