import { Resources, generatedResources } from "@/app/i18n/src";

const resources: Resources = generatedResources["fr-FR"];

export const searchOption = {
  name: resources.navigation.page.actualites(),
  url: "/actualites/recherche",
  placeholder: resources.navigation.header.search.placeholder.actualites(), //TODO: more refined placeholder??
  keyword: "keyword",
};
