import { IEventTrackingBody } from "../../../services/track-event/track-event-service";

export const trackEventApi = (
  details: IEventTrackingBody,
  pathName: string
) => {
  try {
    fetch("/api/ev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...details,
        referer: document.referrer,
        pathName,
      }),
    });
  } catch (e) {}
};
