import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export const joinWhereDefined = (
  separator: string,
  ...values: (string | undefined | false)[]
) => {
  return values.filter(Boolean).join(separator).trim();
};

export const joinWith =
  (separator: string) => (items: string[] | undefined) => {
    return items && joinWhereDefined(separator, ...items);
  };

export const svgToDataURI = (svg: string): `data:image/${string}` =>
  `data:image/svg+xml,${encodeURIComponent(svg.replace(/"/g, "'"))}`;
