import { useEffect } from "react";
/* 
node: the ref to your component. call useRef within your component and pass the node
open: the state of your popup, modal, overlay, etc if it's visible
outsideClickHandler: pass a function to run when clicking outside your component
*/
export const useClickLinkInside = (
  node: React.RefObject<HTMLElement>,
  open: boolean,
  clickLinkHandler: () => void
) => {
  useEffect(() => {
    const handleClickLink = (e: MouseEvent) => {
      if (node.current && node.current.contains(e?.target as Node)) {
        if (e.target instanceof HTMLElement && e.target.closest("a")) {
          clickLinkHandler();
        }

        return;
      }
    };
    if (open) {
      document.addEventListener("mouseup", handleClickLink);
    } else {
      document.removeEventListener("mouseup", handleClickLink);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickLink);
    };
  }, [clickLinkHandler, node, open]);
};

export default useClickLinkInside;
